<template>
  <div class="w-full h-full pt-5 px-3" v-if="show">
    <template>
      <template>
        <div class="flex flex-row justify-start items-center gap-4">
          <div class="flex gap-5">
            <div class="text-darkPurple flex items-center gap-2" @click="navigateBack()">
              <span>
                <Icon
                  icon-name="back_arrow"
                  class="cursor-pointer"
                  size="m"
                />
              </span> Back
            </div>
            <div class="opacity-50 pipe text-romanSilver">|</div>
          </div>
          <h1 class="text-xl font-extrabold">Employees</h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template>
        <div class="flex flex-col mt-5 gap-10">
          <div class="flex flex-col font-bold text-18 text-darkPurple border-b border-borderGrey py-2">
            <div class="">Redeployment Checklist</div>
          </div>
          <div class="shadow w-full grid grid-cols-2 justify-between">
            <div class="col-span-1 flex gap-5 w-full">
              <div class="w-100 h-full" v-if="employeeInfo.photo">
                <img :src="employeeInfo.photo" alt="not-found" class="h-full w-full">
              </div>
              <div v-else class="w-100 rounded-md h-full w-full border flex justify-center items-center">
                <span class="text-center font-bold text-24 text-blueCrayola">
                  {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) || '-' }}
                </span>
              </div>
              <div class="flex flex-col h-full justify-between w-full">
                <div class="flex items-center mb-5 gap-5">
                  <div class="flex items-center text-darkPurple font-bold text-18">{{ employeeInfo.fname || '-' }} {{ employeeInfo.lname || '-' }}</div>
                  <div class="flex items-center promotion-status text-flame text-14">Redeployment in Progress</div>
                </div>
                <div class="flex w-full justify-between gap-5">
                  <div class="text-14 text-romanSilver uppercase font-extrabold text-left w-full">Function:</div>
                  <div class="text-14 text-darkPurple font-bold text-left w-full" v-if="employeeInfo.orgFunction">{{ employeeInfo.orgFunction.name|| '-'}}</div>
                  <span class="text-14 text-darkPurple font-bold text-left w-full" v-else>-</span>
                </div>
                <div class="flex w-full justify-between gap-5">
                  <div class="text-14 text-romanSilver uppercase font-extrabold text-left w-full">Designation:</div>
                  <div class="text-14 text-darkPurple font-bold text-left w-full" v-if="employeeInfo.userDesignation">{{ employeeInfo.userDesignation.name || '-'}}</div>
                  <span class="text-14 text-darkPurple font-bold text-left w-full" v-else>-</span>
                </div>
                <div class="flex w-full justify-between gap-5">
                  <div class="text-14 text-romanSilver uppercase font-extrabold text-left w-full">Job Level:</div>
                  <div class="text-14 text-darkPurple font-bold text-left w-full" v-if="employeeInfo.userLevel">{{ employeeInfo.userLevel.name || '-'}}</div>
                  <span class="text-14 text-darkPurple font-bold text-left w-full" v-else>-</span>
                </div>
                <div class="flex w-full justify-between gap-5">
                  <div class="text-14 text-romanSilver uppercase font-extrabold text-left w-full">Office Location:</div>
                  <div class="text-14 text-darkPurple font-bold text-left w-full" v-if="employeeInfo.employeeOffice">{{  employeeInfo.employeeOffice.name|| '-'}}</div>
                  <span class="text-14 text-darkPurple font-bold text-left w-full" v-else>-</span>
                </div>
                <div class="flex w-full justify-between gap-5">
                  <div class="text-14 text-romanSilver uppercase font-extrabold text-left w-full">Manager:</div>
                  <div class="text-14 text-darkPurple font-bold text-left w-full" v-if="employeeInfo.userReportingTo">{{ employeeInfo.userReportingTo.fname}} {{employeeInfo
                    .userReportingTo.lname}}</div><span class="text-14 text-darkPurple font-bold text-left w-full" v-else>-</span>
                </div>
                <div class="flex w-full justify-between gap-5">
                  <div class="text-14 text-romanSilver uppercase font-extrabold text-left w-full">Length of service:</div>
                  <div class="text-14 text-darkPurple font-bold text-left w-full" v-if="employeeInfo.resumptionDate">{{$getLengthOfService(employeeInfo.resumptionDate) }}</div>
                  <span class="text-14 text-darkPurple font-bold text-left w-full" v-else>-</span>
                </div>
              </div>
            </div>


            <div class="col-span-1 flex justify-between">
              <div class=""></div>
              <div class="btn-cancel text-flame cursor-pointer" @click="navigateBack()">Cancel Redeployment</div>
            </div>
          </div>
          <div class="w-80 flex justify-center tiny bg-borderGrey items-center m-auto">
          </div>
          <div class="shadow w-full grid grid-cols-8 justify-between" v-if="!isLoading">
            <div class="col-span-2 flex flex-col border-r border-borderGrey px-5">
              <div
                v-for="(stepName, index) in steps"
                :key="index"
                :class="{
                  'nav-item capitalize': true,
                  'active': step === stepName,
                  'text-darkPurple': step === stepName,
                  'text-romanSilver': step !== stepName
                }"
              >
                {{ index !== 2 ? stepName : 'Payroll & Relocation Allowance' }}
              </div>
            </div>
            <div class="col-span-6 px-5">

              <div class="w-full flex flex-col gap-5" v-if="step=== 'intro'">
                <div class="intro-desc text-mediumSeaGreen border border-mediumSeaGreen flex gap-2 items-center">
                  <Icon
                  icon-name="info_icon_green"
                  size="s"
                  />
                  You have initiated the process of redeploying {{ employeeInfo.fname ? employeeInfo.fname : '-' }} {{ employeeInfo.lname ? employeeInfo.lname : '-' }} {{ employeeInfo.mname ? employeeInfo.mname.substring(0,1) : '' }}. Before you proceed, please review the redeployment checklist to ensure that your operations continue to run smoothly after this action.
                </div>
                <div class="flex gap-5 items-center justify-end cursor-pointer">
                  <div class="count flex items-center justify-center font-extrabold text-lg p-1">1</div>
                  <div class="flex flex-col justify-center"  @click="navigate(steps[steps.indexOf(step) + 1])">
                    <div class="text-xs text-flame">2/4 Next</div>
                    <div class="text-xs text-darkPurple font-extrabold uppercase">Basic Info</div>
                  </div>
                  <div class=""  @click="navigate(steps[steps.indexOf(step) + 1])">
                    <Icon
                    icon-name="nextArrow"
                    size="xs"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col w-full gap-5" v-if="step === 'basic-info'">
                <div class="w-full grid grid-cols-3 justify-start items-start gap-4">
                  <div class="col-span-1 flex flex-col justify-start gap-2">
                    <p class="text-jet text-sm mr-5">Effective Date</p>
                    <validation-provider class="w-full" name="Effective date" :rules="{required: true}" v-slot="{ errors }">
                      <div :class="errors[0] ? 'border border-desire rounded-md' : 'date-flex'">
                        <datepicker
                          placeholder="--Start Date--"
                          input-class="date-input"
                          style="width:100%;"
                          :rules="['required']"
                          v-model="payload.effectiveDate"
                        />
                      </div>
                      <small class="text-desire">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div class="col-span-1 text-darkPurple">
                    <c-select
                      placeholder="--Select--"
                      :options="locationOptions"
                      label="New Office Location"
                      variant="w-full"
                      :rules="['required']"
                      v-model="payload.newOfficeId"
                      @input="onGetPayBandByOrgParams"
                    />
                  </div>
                  <div class="col-span-1">
                    <c-select
                      placeholder="--Select--"
                      :options="setFunctions"
                      label="New Function"
                      variant="w-full"
                      :rules="['required']"
                      v-model="globalFunctionId"
                    />
                  </div>
                  <div class="col-span-1">
                    <c-select
                      placeholder="--Select--"
                      :options="setDesignation ? setDesignation.designations : []"
                      label="New Role"
                      variant="w-full"
                      :rules="['required']"
                      v-model="globalDesignation"
                      @input="onChangeDesignation"
                    />
                  </div>
                  <div class="col-span-1 text-darkPurple">
                    <div class="text-sm mb-2 text-jet">
                      Job Level
                    </div>
                    <div class="payInfo payInfo_fakeInput text-romanSilver bg-cultured text-center">
                      <span v-if="setLevel">
                        {{ setLevel.name }}
                      </span>
                      <span v-else>Autoload</span>
                    </div>
                  </div>
                  <div class="col-span-1 text-darkPurple">
                    <span class="text-sm mb-2 text-jet">Reporting to</span>
                    <div class="payInfo payInfo_fakeInput text-romanSilver bg-cultured text-center">
                      <span v-if="setReportingTo">{{ setReportingTo.fname }} {{ setReportingTo.lname }}</span>
                      <span v-else>Autoload</span>
                    </div>
                  </div>
                </div>
                <div class="p-2 border border-borderGrey comment">
                  <div class="text-romanSilver text-sm">Comment</div>
                  <div class="text-jet text-sm">You have initiated the process of promoting {{ employeeInfo.fname ? employeeInfo.fname : '-' }} {{ employeeInfo.lname ? employeeInfo.lname : '-' }} {{ employeeInfo.mname ? employeeInfo.mname.substring(0,1) : '' }}. in your organization. Before you proceed, please review the promotion checklist to ensure that your operations continue to run smoothly after the promotion.</div>
                </div>
                <div class="flex justify-end gap-5">
                  <div class="flex gap-5 items-center justify-end cursor-pointer">
                    <div class="" @click="navigate(steps[steps.indexOf(step) - 1])">
                      <Icon
                      icon-name="prevArrow"
                      size="xs"
                      />
                    </div>
                    <div class="flex flex-col justify-center" @click="navigate(steps[steps.indexOf(step) - 1])">
                      <div class="text-xs text-romanSilver">1/4 Previous</div>
                      <div class="text-xs text-darkPurple font-extrabold uppercase">Intro</div>
                    </div>
                  </div>
                  <div class="count flex items-center justify-center font-extrabold text-lg p-1">2</div>
                  <div class="flex gap-5 items-center justify-end cursor-pointer" @click="navigate(steps[steps.indexOf(step) + 1])">
                    <div class="flex flex-col justify-center" >
                      <div class="text-xs text-flame">3/4 Next</div>
                      <div class="text-xs text-darkPurple font-extrabold uppercase">Payroll & Relocation Allowance</div>
                    </div>
                    <div class="">
                      <Icon
                      icon-name="nextArrow"
                      size="xs"
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div class="flex flex-col w-full gap-5" v-if="step === 'payroll'">
                <div class="flex flex-col gap-2">
                  Is this redeployment eligible for salary review?
                  <div class="flex gap-5">
                    <div class="flex items-center gap-2">
                      <input type="radio" name="salaryReviewEligible" :checked="salaryReviewEligible" @change="changeSalaryReviewEligible(true)"/> Yes
                    </div>
                    <div class="flex items-center gap-2">
                      <input type="radio" name="salaryReviewEligible" :checked="!salaryReviewEligible"  @change="changeSalaryReviewEligible(false)"/> No
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-5" v-if="salaryReviewEligible">
                  <div class="flex flex-col gap-2">
                    <div class="text-sm mb-2 text-jet">
                      New Pay Band
                    </div>
                    <div class="grid pay-band justify-between items-center">
                      <div class="text-darkPurple">
                        <div class="payInfo payInfo_fakeInput text-romanSilver bg-cultured text-center flex gap-2 text-xs">
                           <span>{{ payload.payband ?
                          convertToCurrency(payload.payband.rangeMinimum) : '₦0.00'}}</span>
                        </div>
                      </div>
                      <div class="text-jet flex items-end justify-center">
                        to
                      </div>
                      <div class="text-darkPurple flex items-end gap-2">
                        <div class="payInfo payInfo_fakeInput text-romanSilver bg-cultured text-center w-full flex gap-2 text-xs">
                          <span>{{  payload.payband ?
                            convertToCurrency(payload.payband.rangeMaximum) : '₦0.00'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-2">
                    <div class="text-sm mb-2 text-jet">
                      Current Annual Gross Salary
                    </div>
                    <div class="grid grid-cols-1 justify-between items-center">
                      <div class="col-span-1 text-darkPurple">
                        <div class="payInfo payInfo_fakeInput text-romanSilver bg-cultured text-center flex gap-2 text-base">
                          <span>₦</span> <span>{{ employeeInfo.payInfo.grossPay ? employeeInfo.payInfo.grossPay.toLocaleString('en-US') : '-'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-2 justify-between">
                    <div class="flex items-center gap-2">
                      <input type="radio" name="increaseBy" :checked="increaseBy === 'percent'" @change="changeIncreaseByType('percent')"/> Increase By %
                      <input type="radio" name="increaseBy" :checked="increaseBy === 'amount'" @change="changeIncreaseByType('amount')"/> Increase By amount
                    </div>
                    <div class="grid grid-cols-1 justify-between items-center">
                      <div class="col-span-1 text-darkPurple grid grid-cols-7">
                        <div class="col-span-1 border border-romanSilver text-center amount_ text-jet">
                          ₦
                        </div>
                        <div class="col-span-6 input_ border border-romanSilver"><input type="number" class="h-full w-full px-5 text-base" placeholder="--Enter--" v-model="increaseValue"></div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-2">
                    <div class="text-sm mb-2 text-jet">
                      New Annual Gross Salary
                    </div>
                    <div class="grid grid-cols-1 justify-between items-center">
                      <div class="col-span-1 text-darkPurple">
                        <div class="payInfo payInfo_fakeInput text-romanSilver bg-cultured text-center flex gap-2 text-base">
                          <span>₦</span> <span>{{ newAnnualSalary ? newAnnualSalary.toLocaleString('en-US') : '-'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="flex flex-col gap-2">
                  Is this redeployment eligible for Relocation Allowance?
                  <div class="flex gap-5">
                    <div class="flex items-center gap-2">
                      <input type="radio" name="relocationAllowanceEligible" :checked="relocationAllowanceEligible" @change="changeRelocationAllowanceEligible(true)"/> Yes
                    </div>
                    <div class="flex items-center gap-2">
                      <input type="radio" name="relocationAllowanceEligible" :checked="!relocationAllowanceEligible" @change="changeRelocationAllowanceEligible(false)"/> No
                    </div>
                  </div>
                </div>
                <div class="flex justify-end gap-5">
                  <div class="flex gap-5 items-center justify-end cursor-pointer" @click="navigate(steps[steps.indexOf(step) - 1])">
                    <div class="">
                      <Icon
                      icon-name="prevArrow"
                      size="xs"
                      />
                    </div>
                    <div class="flex flex-col justify-center">
                      <div class="text-xs text-romanSilver">2/4 Previous</div>
                      <div class="text-xs text-darkPurple font-extrabold uppercase">Basic Info</div>
                    </div>
                  </div>
                  <div class="count flex items-center justify-center font-extrabold text-lg p-1">3</div>
                  <div class="flex gap-5 items-center justify-end cursor-pointer" @click="navigate(steps[steps.indexOf(step) + 1])">
                    <div class="flex flex-col justify-center">
                      <div class="text-xs text-flame">4/4 Next</div>
                      <div class="text-xs text-darkPurple font-extrabold uppercase">Confirm</div>
                    </div>
                    <div class="">
                      <Icon
                      icon-name="nextArrow"
                      size="xs"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col w-full gap-10" v-if="step === 'confirm'">
                <div class="intro-desc text-mediumSeaGreen border border-mediumSeaGreen flex gap-2 items-center">
                  <Icon
                  icon-name="info_icon_green"
                  size="s"
                  />
                  Congratulations! You have finished going through the redeployment checklist. Please confirm that you are ready to redeploy and exit. This action will take effect as soon as it is approved.
                </div>
                <div class="w-full flex justify-between">
                  <div class="flex gap-5">
                    <div class="flex gap-5 items-center justify-end cursor-pointer" @click="navigate(steps[steps.indexOf(step) - 1])">
                      <div class="">
                        <Icon
                        icon-name="prevArrow"
                        size="xs"
                        />
                      </div>
                      <div class="flex flex-col justify-center">
                        <div class="text-xs text-romanSilver">3/4 Previous</div>
                        <div class="text-xs text-darkPurple font-extrabold uppercase">Payroll</div>
                      </div>
                    </div>
                    <div class="count flex items-center justify-center font-extrabold text-lg p-1">4</div>
                  </div>
                  <div class="flex gap-5">
                    <div class="radius text-flame border border-flame" @click="navigateBack()">
                      <button class="font-semibold rounded-md text-center radius buttonFocus btn"> Cancel </button>
                    </div>
                    <div class="radius bg-flame text-white"  @click="onSubmit()">
                      <button class="font-semibold rounded-md text-center radius buttonFocus btn"> Confirm </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <loader v-else size="xxs" :loader-image="false" />
        </div>
      </template>
    </template>
    <template>
      <Modal v-if="isPaybandWarning" class="">
        <Card class=" p-2 px-3 flex flex-col gap-3 max-w-md">
          <template>
            <div class="border-b border-romanSilver">
              <p class="text-jet font-bold text-base">Action Required!</p>
            </div>
            <div class="w-full flex flex-col justify-start gap-2">
              <div class="relative mb-5 w-full">
                <div class="w-full px-5 py-2 flex self-center mb-2 rounded-md bg-yellow-100 border-carrotOrange border">
                  <div class="flex justify-start items-center gap-2">
                    <Icon icon-name="icon-info" size="xs" class="text-carrotOrange" />
                    <p class="text-carrotOrange w-full rounded-md text-center text-sm">
                      <span v-if="newAnnualSalary < payload.payband.rangeMinimum">
                        New annual gross salary is less than pay range
                      </span>
                      <span v-if="newAnnualSalary > payload.payband.rangeMaximum">
                        New annual gross salary is more than pay range
                      </span>
                    </p>
                  </div>
                </div>
                <div class="w-full flex flex-col justify-start items-start gap-1">
                  <div class="flex justify-start items-center gap-2">
                    <p class="font-semibold text-sm text-jet">Salary Range:</p>
                    <p class="font-bold text-sm leading-5 text-jet">
                      <span v-if="payload.payband">
                        {{ convertToCurrency(payload.payband.rangeMinimum) }}
                          -
                        {{ convertToCurrency(payload.payband.rangeMaximum) }}
                      </span>
                      <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-center gap-2">
                    <p class="font-semibold text-sm text-jet">Current Annual Gross:</p>
                    <p class="font-bold text-sm text-jet">
                      {{ convertToCurrency(employeeInfo.payInfo.grossPay) }}
                    </p>
                  </div>
                  <div class="flex justify-start items-center gap-2">
                    <p class="font-semibold text-sm text-jet">New Annual Gross:</p>
                    <p class="font-bold text-sm text-jet">
                      {{ convertToCurrency(newAnnualSalary) }}
                    </p>
                  </div>
                </div>
              </div>
              <p>Do you wish to continue?</p>
              <div class="flex justify-end items-center gap-2">
                <Button
                  label="Cancel"
                  class="button-class button-class-secondary"
                  @onClick="isPaybandWarning = false"
                  :disabled="isLoading"
                />
                <Button
                  class="button-class button-class-pimary btn-bg"
                  label="Continue"
                  @onClick="onSubmit"
                  :disabled="isLoading"
                />
              </div>
            </div>
          </template>
        </Card>
      </Modal>
    </template>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import {  ValidationProvider } from "vee-validate"
import CSelect from "@scelloo/cloudenly-ui/src/components/select"
import Datepicker from "vuejs-datepicker"
import * as _ from "lodash"
import { format } from "date-fns"
import organisationMixin from "@/utilities/organisationMixin";
import Modal from "@/components/Modal"
import Card from "@scelloo/cloudenly-ui/src/components/card"
import Button from "@scelloo/cloudenly-ui/src/components/button"

export default {
  components: {
    ValidationProvider,
    Breadcrumb,
    CSelect,
    Datepicker,
    Modal,
    Card,
    Button,
  },
  mixins: [organisationMixin],
  data() {
    return {
      step: 'intro',
      steps: [
        'intro',
        'basic-info',
        'payroll',
        'confirm',
      ],
      increaseBy : 'percent',
      increaseValue : '',
      salaryReviewEligible : true,
      relocationAllowanceEligible : true,
      newAnnualSalary: '',
      selectedFunctionId: '',
      designationOptions: [],
      allDesignationOptions: [],
      functionOptions: [],
      locationOptions: [],
      payload: {
        effectiveDate: '',
        newOfficeId: '',
        payband: {}
      },
      isPaybandWarning: false,
      paybandOptions: [],
      employeesOptions: [],
      paybandByLevelId: {},
      queryString: '',
      designation: {},
      isLoading: false,
      employeeInfo: {},
      show: false,
      newManager: {},
      isFetchingPayBand: false,
      breadcrumbs: [
        { disabled: false, text: "Employee", href: "", id: "Employee" },
        { disabled: false, text: "Employee Directory", href: "EmployeeDirectory", id: "EmployeeDirectory" },
        { disabled: false, text: "Redeployment", href: "redeployment", id: "Redeployment" },
      ],
    };
  },
  methods: {
    navigate(step) {
      if(this.steps.indexOf(step) < this.steps.indexOf(this.step)){
        this.step = step;
      }else{
        const isValid = this.validateInputs(this.step);
        if(isValid){
          this.step = step
        }
      }
    },
    navigateBack() {
      this.$router.go(-1);
    },
    validateInputs(step) {
      let isValid;
      switch (step) {
          case 'basic-info':
            if(!this.payload.effectiveDate || !this.payload.newOfficeId || !this.globalDesignation){
              this.$toasted.error(`Enter required fields!`, { duration: 3000 })
              isValid = false;
            }else{
              isValid = true;
            }
          break;
        case 'payroll':
          if(this.salaryReviewEligible && !this.increaseValue){
            this.$toasted.error(`Enter required fields!`, { duration: 3000 })
            isValid = false;
          }else{
            isValid = true;
          }
          break;

        default:
        isValid = true;
        break;
      }
      return isValid
    },
    onSubmit() {
      const {rangeMinimum, rangeMaximum} = this.payload.payband
      const newGross = this.newAnnualSalary
      const isInRange = _.inRange(newGross, rangeMinimum, rangeMaximum)
      const isPayband = _.size(_.keys(this.payload.payband))
      if(!isInRange && !!isPayband && !this.isPaybandWarning){
        this.isPaybandWarning = true
        return undefined
      }
      this.isLoading = true
      const payload = {
        ...this.payload,
        orgId: this.$orgId,
        userId: this.employeeInfo.userId,
        increaseBy: this.increaseBy === 'amount' ? 'amount' : 'percentage',
        payRangeStart: this.payload.payband ? this.payload.payband.rangeMinimum : 0,
        payRangeEnd: this.payload.payband ? this.payload.payband.rangeMaximum : 0,
        newLevelId: this.setLevel ? this.setLevel.id : null,
        initiatedBy: this.$AuthUser.id,
        reportingTo: this.setReportingTo ?  this.setReportingTo.id : null,
        functionId: this.globalFunctionId,
        payrollReview: this.salaryReviewEligible,
        currGrossAnnualSalary: this.employeeInfo ? this.employeeInfo.payInfo.grossPay : 0,
        relocationAllowance : this.relocationAllowanceEligible,
        effectiveDate: format(new Date(this.payload.effectiveDate), "yyyy-MM-dd"),
        newOfficeId: this.payload.newOfficeId,
        newManagerId: this.setReportingTo ?  this.setReportingTo.id : null,
        newGrossAnnualSalary: this.newAnnualSalary,
        newDesignationId: this.globalDesignation ? this.globalDesignation : null,
      }
      const query = this.payload.id ? '&update=true' : ''
      return this.$_employeeActionRedeployeEmployee({ payload, query }).then(() => {
        const message = "Employee redeployment request successfully sent for approval"
        this.$toasted.success(message, {  duration: 3000 })
        this.isLoading = false
        return this.navigateBack();
      }).catch((error) => {
        this.$toasted.error(error, { duration: 3000 })
        this.isLoading = false
      })
    },
    changeSalaryReviewEligible(bool) {
      this.salaryReviewEligible = bool;
    },
    changeRelocationAllowanceEligible(bool) {
      this.relocationAllowanceEligible = bool;
    },
    changeIncreaseByType(type) {
      this.increaseBy = type;
    },
    async onChangeDesignation(){
      this.onGetPayBandByOrgParams()
    },
    async onGetPayBandByOrgParams(){
      this.isFetchingPayBand = true
      await this.$_getPaygradeByOrgParameters({
        orgId: this.$orgId,
        levelId: this.setLevel.id || null,
        designationId: this.globalDesignation,
        functionId: this.globalFunctionId,
        locationId: this.payload.newOfficeId
      }).then(({ data }) => {
        this.payload.payband = data.data || {}
        this.isFetchingPayBand = false
      }).catch((error) => {
        this.$toasted.error(error.message, {duration: 3000})
        this.isFetchingPayBand = false
        this.payload.payband = {}
      })
    },
    async getOrgOfficeLocations(){
      await this.$_getHumanarLocations().then(({ data }) => {
        this.locationOptions = data.outlets?.map((outlet) => ({
          ...outlet, value: outlet.id
        }))
      })
    },
  },
  async mounted() {
    if(this.$route.params.employeeInfo){
      this.employeeInfo = this.$route.params.employeeInfo;
      this.newAnnualSalary = this.$route.params.employeeInfo.payInfo.grossPay;
      this.show = true;
    }else{
        this.$router.push({
        name: 'Redeployment',
      });
    }
    this.isLoading = true
    await this.getOrgOfficeLocations()
    this.isLoading = false
  },
  watch: {
    increaseValue: {
      deep: true,
      handler(newIncreaseValue) {
        if(this.increaseBy === 'amount'){
          this.newAnnualSalary = Number(newIncreaseValue) + Number(this.employeeInfo.payInfo.grossPay);
        }
        else if(this.increaseBy === 'percent'){
          this.newAnnualSalary = (((Number(newIncreaseValue))/100) * Number(this.employeeInfo.payInfo.grossPay)) + Number(this.employeeInfo.payInfo.grossPay);
        }
      }
    },
    increaseBy: {
      deep: true,
      handler(newIncreaseBy) {
        if(newIncreaseBy === 'amount'){
          this.newAnnualSalary = Number(this.increaseValue) + Number(this.employeeInfo.payInfo.grossPay);
        }
        else if(newIncreaseBy === 'percent'){
          this.newAnnualSalary = (((Number(this.increaseValue))/100) * Number(this.employeeInfo.payInfo.grossPay)) + Number(this.employeeInfo.payInfo.grossPay);
        }
      }
    },
  },
};
</script>

<style scoped>
  .pipe{
    font-size: 24px;
  }
  .shadow{
    box-shadow: 0px 1px 3px 0px #0807081A;
    box-shadow: 0px 1px 2px 0px #2D31420F;
    padding: 12px 16px 12px 16px;
    border-radius: 5px;
  }
  .count{
    box-shadow: 0px 1px 3px 0px #0807081A;
    box-shadow: 0px 1px 2px 0px #2D31420F;
    border-radius: 5px;
    height: 40px;
    width: 40px;
  }
  .btn-cancel{
    padding: 8px 12px 8px 12px;
    border-radius: 5px;
    border: 1px solid #F15A29;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: fit-content;
  }
  .w-fit{
    width: fit-content;
  }
  .w-100{
    width: 100px;
  }
  .promotion-status{
    padding: 4px 8px 4px 8px;
    background-color: #E993231F;
    color: #E99323;
    border: 1px solid #E99323;
    border-radius: 5px;
  }
  .w-80{
    width: 80%;
  }
  .tiny{
    height: 1px;
    opacity: 0.4;
  }
  .nav-item{
    padding: 5px 12px;
    font-weight: 600;
  }
  .nav-item.active{
    border-right: 4px solid #F15A29;
  }
  .intro-desc{
    padding: 12px 16px 12px 16px;
    background-color: #13B56A0F;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid #13B56A;
    border-radius: 5px
  }
  .comment{
    border-radius: 4px;
  }
  .pay-band{
    grid-template-columns: 7fr 1fr 7fr;
    gap: 10px;
    width: 100%;
    max-width: 100%;
  }
  .input_{
    height: 40px;
    border-radius: 0px 2px 2px 0px;
  }
  .input_ input{
    outline: none;
  }
  .amount_{
    border-radius: 2px 0px 0px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px ;
    font-weight: 700;
  }
  .payInfo_fakeInput{
    background-color: #EEEEEE;
  }
  .btn{
    padding: 12px 24px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>